//https://bootstrap.build/app
@use "sass:map";

$primary: #436a51 !default;
$secondary: #3d513b !default;
$success: #0e970a !default;
$info: #636563 !default;
$warning: #787d85 !default;
$danger: #b12836 !default;
$light: #bdbfc3 !default;
$dark: #5b5e64 !default;

$enable-negative-margins: true;
$font-size-base: 0.8rem !default; // Assumes the browser default, typically `16px`

/* The following line can be included in a src/App.scss */
// https://react-bootstrap.github.io/getting-started/introduction/
@import "~/node_modules/bootstrap/scss/bootstrap";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.app-header {
  background-color: #b0cbe3;
  min-height: 2.5rem;
}

.app-menu {
  background-color: #f5eae0;
}

.app-footer {
  background-color: #b9b9b9;
  max-height: 2rem;
  min-height: 1.5rem;
}

.cut-text {
  text-overflow: ellipsis;
  overflow: hidden;

  display: inline-block;
  width: 100px;
  height: 1.2em;
  white-space: nowrap;
}

.cut-text-simple {
  display: block;
  max-width: 8rem;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.nav-menu-icon {
  width: 1.2rem !important;
  height: 1.2rem !important;
}

.active-nav-item {
  background-color: rgb(183 169 159);
  width: 100%;
}

$menuClosedWidth: 40px;
$menuMaxWidth: 150px;
$menuMinWidth: 125px;

.closed-menu {
  max-width: $menuClosedWidth !important;
  width: $menuClosedWidth !important;
  min-width: $menuClosedWidth !important;
}

.open-menu {
  max-width: $menuMaxWidth !important;
  min-width: $menuMinWidth !important;
}

#root {
  width: 100%;
  min-height: 100vh;
  min-width: 300px;
  top: 0;
  bottom: 0;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.icon-text-btn {
  width: 40px !important;
}
@media (min-width: map.get($grid-breakpoints, "sm")) {
  .icon-text-btn {
    width: 140px !important;
  }
}
